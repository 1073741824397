<style lang="less">
.assets-check-page {
  .fm-table-border-row .fm-table-head-cells {
    border-top: 0 !important;
  }
  .search-input-div {
    .search-input input {
      width: 100% !important;
    }
  }
}
</style>

<style scoped lang="less">
  .assets-check-page {
    padding: 16px;
    height: 100%;
    background-color: rgba(68, 96, 187, 0.05);
    display: flex;
  }
  .content-org {
    width: 208px;
    height: 100%;
    background-color: #FFF;
  }
  .content {
    margin-left: 16px;
    height: 100%;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #FFF;
  }
  .table {
    flex: 1;height: 0;
  }
  .search-input-div {
    display: flex;
    justify-content: center;
  }
  .org-list {
    padding: 0 16px;
    margin-top: 9px;
    overflow-y: auto;
    height: calc(100% - 34px - 48px - 9px);
  }
  .org-item {
    width: 100%;
    height: 40px;
    background: #fff;
    font-size: 16px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08);
    margin-bottom: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }
  .org-item-a {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  }
  .org-item-label {
    margin-left: 16px;
    color: #000;
    width: calc(100% - 16px - 60px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .t-actions {
    margin: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 9px;
    .l-btn {
      display: flex;
    }
  }
</style>

<template>
  <div class="assets-check-page">
    <div class="content-org">
      <fm-title titleText="机构列表"></fm-title>
      <div class="search-input-div">
        <SearchInput trigger="input" style="width: 148px;" :serchText="serchText" @change="sChange" placeholder="请输入机构名称"></SearchInput>
      </div>
      <div class="org-list">
        <a href="javascript:;" :class="{'org-item-a' : chooseOrgValue === item.value}" @click="chooseOrgValue = item.value" class="org-item" v-for="(item, index) in showOrgList" :key="index">
          <div class="org-item-label">{{item.label}}</div>
          <CircleDom :border-width="2" :size="28" :rate="item.rate"></CircleDom>
        </a>
      </div>
    </div>
    <div class="content">
      <fm-title :titleText="chooseOrg.label" :noteText="'总数: ' + dataList.length"></fm-title>
      <div class="t-actions">
        <div class="l-btn">
          <fm-btn style="margin-right: 24px;" @click="serchStatus = 'un'">未盘点</fm-btn>
          <fm-btn style="margin-right: 24px;" @click="serchStatus = 'ed'">已盘点</fm-btn>
          <fm-btn @click="serchStatus = 'all'">全部</fm-btn>
        </div>
        <div>
          <fm-btn
          tint
          type="info"
          @click="notCheck">一键未盘点</fm-btn>
          <fm-btn
          tint
          type="info"
          @click="outPut">导出</fm-btn>
          <fm-btn
          tint
          type="info"
          @click="clickTitleMenu('log')">盘点记录</fm-btn>
        </div>
      </div>
      <div class="table">
        <fm-table-new
          :columns="columns"
          :simple-filter="true"
          :auto-height="true"
          border="row"
          :data-list="dataList"
          :stripe="false"
          emptyPlaceholder="-">
          <div slot="empty" style="margin: 30px 0;">
            <img src="/static/images/null.png" style="width: 40px;"/>
            <div>暂无数据</div>
          </div>
          <div slot="checkTimes" slot-scope="{ row }">
            <span :style="{color: row.checkTimes ? 'green' : 'red'}">{{row.checkTimes ? '已盘点' : '未盘点'}}</span>
            <fm-btn size="small" style="margin-left: 20px;" @click="setCheckStatus(row, !row.checkTimes ? 1 : 0)">{{!row.checkTimes ? '已盘点' : '未盘点'}}</fm-btn>
          </div>
        </fm-table-new>
      </div>
    </div>
    <fm-modal :mask-closable="false" v-model="status.log" width="80vw" title="实时盘点记录" @cancel="closeLog">
      <div style="height: 70vh">
        <fm-table-new
          v-if="status.log"
          :columns="logColumns"
          :auto-height="true"
          border="row"
          :data-list="logList"
          :stripe="false"
          emptyPlaceholder="-">
          <div slot="empty" style="margin: 30px 0;">
            <img src="/static/images/null.png" style="width: 40px;"/>
            <div>暂无数据</div>
          </div>
          <div slot="checkTimes" slot-scope="{ row }">
            <span :style="{color: row.assetsCheckRecordAction === 'check' ? 'green' : 'red'}">{{row.assetsCheckRecordAction === 'check' ? '已盘点' : '未盘点'}}</span>
          </div>
        </fm-table-new>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import { assetsRequest, assetsCheckRecordRequest } from '../../api'
import SearchInput from '@/components/base/SearchInput'
import CircleDom from '@/components/base/CircleDom'

import {
  fileHepler
} from '@/fmlib'

let logTimer = null
let refreshTimer = null
let inputTimer = null

function updateAssetsOrg (data) {
	let orgMap = {
		all: {
			label: '全部机构',
			value: 'all',
			checkEd: 0,
			unCheck: 0,
			total: 0,
			rate: 0
		},
		other: {
			label: '未配置',
			value: 'other',
			checkEd: 0,
			unCheck: 0,
			total: 0,
			rate: 0
		}
	}
	
	data.forEach((v) => {
		let orgId = v.useOrgId
		let orgName = v.useOrgName
		if (!orgId) {
			orgId = 'other'
			orgName = '未配置'
		}
		if (!orgMap[orgId]) {
			orgMap[orgId] = {
				value: orgId,
				label: orgName,
				checkEd: 0,
				unCheck: 0,
				total: 0,
				rate: 0
			}
		}
		orgMap[orgId].total += 1
		orgMap.all.total += 1
		let isCheckEd = v.checkTimes && v.checkTimes > 0
		orgMap.all[isCheckEd ? 'checkEd' : 'unCheck'] += 1
		orgMap[orgId][isCheckEd ? 'checkEd' : 'unCheck'] += 1
	})
	
	const orgList = Object.keys(orgMap).filter(v => !['all', 'other'].includes(v)).map((key) => {
		orgMap[key].rate = Number((orgMap[key].checkEd / orgMap[key].total).toFixed(2))
		return orgMap[key]
	})
	
	orgMap.all.rate = Number((orgMap.all.checkEd / orgMap.all.total).toFixed(2))
	orgMap.other.rate = Number((orgMap.other.checkEd / orgMap.other.total).toFixed(2))
	
	return [orgMap.all, ...orgList, orgMap.other]
}

export default {
  components: {
    SearchInput,
    CircleDom
  },
  data () {
    return {
      chooseOrgValue: 'all',
      serchStatus: 'all',
      orgList: [{
        label: '全部机构',
        value: 'all',
        checkEd: 0,
        unCheck: 0,
        total: 0,
        rate: 0
      }],
      serchText: '',
      loading: {
        data: false,
        log: false
      },
      status: {
        log: false,
        data: true
      },
      data: [],
      total: 0,
      logList: [],
      search: {
        pageSize: 10,
        pageNum: 1,
        isCheck: 1
      }
    }
  },
  computed: {
    chooseOrg () {
      return this.orgList.find(v => v.value === this.chooseOrgValue)
    },
    showOrgList () {
      let data = this.orgList
      if (this.serchText) {
        data = data.filter(v => v.label.includes(this.serchText))
      }
      return data
    },
    dataList () {
      let data = this.data
      if (this.chooseOrgValue !== 'all') {
        if (this.chooseOrgValue === 'other') {
          data = data.filter(v => !v.useOrgId)
        } else {
          data = data.filter(v => v.useOrgId === Number(this.chooseOrgValue))
        }
      }
      if (this.serchStatus !== 'all') {
        if (this.serchStatus === 'un') {
          data = data.filter(v => !v.checkTimes)
        } else {
          data = data.filter(v => v.checkTimes)
        }
      }
      return data
    },
    titleMenus () {
      return [
        { label: '盘点记录', key: 'log' }
      ]
    },
    columns () {
      return [
        { title: '资产编号', field: 'code' },
        // { title: '项目名称', field: 'goodsName' },
        { title: '资产名称', field: 'name' },
        {
          title: '取得日期', field: 'getTime',
          render: (h, row) => {
            return h('div', row.getTime ? row.getTime.slice(0, 10) : '-')
          }
        },
        { title: '使用部门', field: 'useOrgName' },
        { title: '管理部门', field: 'manageOrgName' },
        { title: '盘点状态', field: 'checkTimes', slot: 'checkTimes' }
      ]
    },
    outPutColumns () {
      return [
        { title: '资产编号', field: 'code' },
        { title: '资产国标大类', field: 'rootAssetsTypeName' },
        { title: '资产名称', field: 'name' },
        { title: '价值', field: 'jz' },
        { title: '数量', field: 'num' },
        { title: '品牌', field: 'pp' },
        { title: '规格型号', field: 'ggxh' },
        { title: '备注', field: 'bz' }
      ]
    },
    logColumns () {
      return [
        { title: '资产编号', field: 'code' },
        // { title: '项目名称', field: 'goodsName' },
        { title: '资产名称', field: 'name' },
        { title: '盘点时间', field: 'assetsCheckRecordCreateTime' },
        { title: '盘点类型', field: 'assetsCheckRecordSourceType',
          render: (h, row) => {
            return h('div', row.assetsCheckRecordSourceType === 'scan' ? 'PDA盘点' : '手动盘点')
          }
        },
        { title: '盘点人员', field: 'assetsCheckRecordUserName' },
        {
          title: '取得日期', field: 'getTime',
          render: (h, row) => {
            return h('div', row.getTime ? row.getTime.slice(0, 10) : '-')
          }
        },
        { title: '使用部门', field: 'useOrgName' },
        { title: '管理部门', field: 'manageOrgName' },
        { title: '盘点状态', field: 'assetsCheckRecordAction', slot: 'checkTimes' }
      ]
    }
  },
  methods: {
    async notCheck () {
      let checkedData = this.dataList.filter(v => v.checkTimes)
      if (checkedData.length === 0) {
        this.$notice.success('无未盘点资产')
        return
      }
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定将' + checkedData.length + '条资产盘点状态设置为未盘点么?'})
      if (result) {
        await assetsRequest.uncheck({
          assetsIds: checkedData.map(v => v.id).join(',')
        })
        checkedData.forEach(v => v.checkTimes = 0)
        this.orgList = updateAssetsOrg(this.data)
        this.$notice.success('操作成功')
      }
    },
    outPut () {
      let statusLabelMap = {
        un: '未盘点',
        ed: '已盘点',
        all: '全部'
      }
      fileHepler.outPutTableData(this.dataList, this.outPutColumns, this.chooseOrg.label + statusLabelMap[this.serchStatus] + '资产.xlsx')
    },
    sChange (data) {
      clearTimeout(inputTimer)
      inputTimer = setTimeout(() => {
        this.serchText = data
      }, 300)
    },
    clickTitleMenu (key) {
      if (key === 'log') {
        this.showLog()
      }
    },
    showLog () {
      this.status.log = true
      this.status.data = false
      clearTimeout(refreshTimer)
      this.refreshLog()
    },
    closeLog () {
      clearTimeout(logTimer)
      this.status.data = true
      this.updateData()
    },
    async refreshLog () {
      if (!this.loading.log && this.status.log) {
        clearTimeout(logTimer)
        this.loading.log = true
        const res = await assetsCheckRecordRequest.get(this.search)
        this.logList = res.data
        clearTimeout(logTimer)
        this.loading.log = false
        logTimer = setTimeout(this.refreshLog, 3000);
      }
    },
    async updateData () {
      if (!this.loading.data && this.status.data) {
        clearTimeout(refreshTimer)
        this.loading.data = true
        
        const lastTime = this.data.reduce((a, b) => {
          if (b.statusTime && new Date(b.statusTime).getTime() > new Date(a).getTime()) {
            return b.statusTime
          } else {
            return a
          }
        }, 0)

        const updateList = await assetsRequest.get(lastTime ? { minStatusTime: lastTime } : {})

        updateList.forEach(item => {
          const index = this.data.findIndex(v => v.id === item.id)
          if (index > -1) {
            this.data.splice(index, 1, item)
          }
        })

        if (updateList.length) {
          this.orgList = updateAssetsOrg(this.data)
        }

        this.loading.data = false
        refreshTimer = setTimeout(this.updateData, 3000);
      }
    },
    async loadData () {
      this.data = await assetsRequest.get()
      this.orgList = updateAssetsOrg(this.data)
      this.total = this.data.length
      refreshTimer = setTimeout(this.updateData, 3000);
    },
    async setCheckStatus (row, status) {
      await assetsCheckRecordRequest.check({
        assetsId: row.id,
        checkAction: status ? 'check' : 'unCheck'
      })
      row.checkTimes = status
      this.$notice.success('操作成功')
    }
  },
  mounted () {
    this.loadData()
  },
  destroyed () {
    clearTimeout(refreshTimer)
    clearTimeout(logTimer)
  }
}
</script>
